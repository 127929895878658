.companies_c {
    display: flex;
    justify-content: center;
    gap: 2rem;
    padding: 4rem 5rem;
    align-items: center;
    overflow: hidden;
}

.CompanyImg {
    width: 14%;
    max-height: 5rem;
    object-fit: contain;
}
