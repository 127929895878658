.aboutUs-c{
    display: flex;
    gap: 2rem;
}
.aboutUsImgs{
    display: flex;
    justify-content: space-between;
    max-height: 80vh;
}
.aboutUsImg{
    width: 48% !important;
}

.aboutUsTexts,.aboutUsImgs{
    width: 50%;
}
.aboutUsImg_1{
    height: 80%;
    width: auto;
    align-self:flex-end;
}

/* About Us Texts */
.aboutUsTexts{
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1rem;
    text-align: start;
}
.aboutUsTexts p{
    width: 100%;
}
.aboutSub{
    color: #cda274;
    font-weight: 600;
}
.aboutSub::before{
    content: "";
    margin-right: 5px;
    display: inline-block;
    align-self: center;
    width: 2rem;
    height: 1px;
    border-bottom: 2px solid #cda274;
}
.aboutTitle{
    font-size: 2.5rem;
    font-weight: 600;
    line-height: 1.2;
}
.aboutCoute{
    padding: 0 1rem;
    border-right: 4px solid #cda274;
    color: #aaa;
    font-weight: 500;
    font-style: italic;
}
.aboutText{
    list-style-type:square;
    color: #aaa;
    font-weight: 500;
    line-height: 2.7rem;
}
.aboutTextItem{
    font-size: 1.2rem;
}
.borderLeft{
    border-left: 4px solid #cda274;
    border-right: 0;
}
.borderRight{
    border-right: 4px solid #cda274;
    border-left: 0;
}