@import "../../App.css";

.MNav{
    position: fixed;
    display: flex;
    flex-direction: column;
    background-color: #333;
    height: 100%;
    z-index: 99;
    align-items: flex-start;
    justify-content: center;
    gap: 2rem;
    font-size: 2rem;
}
.MNavbar-brand{
    padding-left: 2rem;
    width: 11rem;
    max-width: 50%;
}
.MNav-list-c{
    width: 100%;
}
.MNav-list{
    width: 100%;
    gap: 0;
    align-items: flex-start;
}
.MNav-item{
    padding: 1rem 0 1rem 2rem;
    width: 100%;
    border-top: 1px solid var(--secondary-gold);

}
.navClose{
    position: absolute;
    top: 1rem;
    line-height: 0;
    right: 1rem;
}
.navClose:hover *{
    color: var(--secondary-gold) !important;
}
.subLink{
    font-size: 1.5rem;
}
.subLinks{
    display: flex;
    flex-direction: column;
    gap: .5rem;
    padding: 0 2rem 1rem 2rem;
}