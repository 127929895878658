.beforeAfter_c {
    padding-right: 0;
    padding-left: 0;
}

.beforeAfterSub {
    color: #cda274;
    font-size: 1.5rem;
    font-weight: 600;
}

.beforeAfterSub::before {
    content: "";
    margin-right: 5px;
    display: inline-block;
    align-self: center;
    width: 2rem;
    height: 1px;
    border-bottom: 2px solid #cda274;
}

.beforeAfterTitle_c {
    font-size: 2.5rem;
    font-weight: 600;
    line-height: 1.2;
}
.beforeAfterTitle{
    padding: .5rem 0;
}
.beforeAfter {
    position: relative;
    padding: 2rem 0 0 0;
}

.beforeAfterShape {
    position: absolute;
    width: 45%;
    height: 75%;
    background-color: #323232;
    z-index: -1;
    right: 0;
    top: 7rem
}

.beforeAfterContent {
    position: relative;
    width: 94%;
    height: 40rem;
}

.beforeAfterContent input {
    width: 100%;
    position: absolute;
    z-index: 8;
    top: 50%;
    cursor: pointer
}
.beforeLabel{
    position: absolute;
    background: var(--secondary-gold);
    width: .4rem;
    z-index: 6;
    height: 99%;
    left: 49.27%;
    top: 50%;
    transform: translate(-50%,-49.7%);
}
.afterLabel{
    position: absolute;
    top: 52%;
    left: 49.3%;
    transform: translate(-50%,-50%);
    z-index: 7;
    font-size: .8rem;
    background: #333;
    padding: .55rem .4rem;
    border: 2px solid var(--secondary-gold);
    border-radius: 50%;
    min-width: fit-content;
    white-space: nowrap;
}

.beforeAfterContent:hover .beforeAfterShapeBg {
    background-color: #00000310
}

.beforeAfterContent img {
    height: 100%;
    position: absolute;
    -o-object-fit: cover;
    object-fit: cover;
    -o-object-position: 0 5px;
    object-position: 0 5px
}

.beforeAfterShapeBg {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 4;
    -webkit-transition: all 0.2s;
    transition: all 0.2s
}

.rangeStyle::after {
    display: block;
    content: "";
    width: 20px;
    border: 4px solid var(--primary-color);
    height: 20px;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    padding: 1rem;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%)
}
.after_c{
    width: 100%;
    max-height: 100%;
}
.after{
    height: fit-content;
    width: 49.3%;
}

/*********** Baseline, reset styles ***********/
input[type="range"] {
    -webkit-appearance: none;
    appearance: none;
    background: transparent;
    cursor: pointer;
    width: 100%;
}

/* Removes default focus */
input[type="range"]:focus {
    outline: none;
}

/******** Chrome, Safari, Opera and Edge Chromium styles ********/
/* slider track */
input[type="range"]::-webkit-slider-runnable-track {
    background-color: transparent;
    border-radius: 0.5rem;
    height: 0.5rem;
}

/* slider thumb */
input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    /* Override default look */
    appearance: none;
    margin-top: -4px;
    /* Centers thumb on the track */
    background-color: transparent;
    /* border: 3px solid var(--secondary-gold); */
    padding: 1rem;
    border-radius: 50%;
    height: 1rem;
    width: 1rem;
}

/*********** Firefox styles ***********/
/* slider track */
input[type="range"]::-moz-range-track {
    background-color: transparent;
    border-radius: 0.5rem;
    height: 0.5rem;
}

/* slider thumb */
input[type="range"]::-moz-range-thumb {
    background-color: transparent;
    border: none;
    /*Removes extra border that FF applies*/
    /* border-radius: 0.5rem; */
    height: 1rem;
    width: 1rem;
}



/* ----------------------------------- */

.rangeStyle {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-51%, -49.5%) rotate(0deg);
    transform: translate(-51%, -49.5%) rotate(0deg);
    content: " ";
    display: block;
    width: 3px;
    background-color: red;
    z-index: 7;
    width: 2rem;
    height: 2rem;
}

.rangeStyle::after {
    display: block;
    content: "";
    width: 20px;
    border: 4px solid var(--primary-color);
    height: 20px;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    padding: 1rem;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%)
}

.evaluationsContainer {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    overflow: scroll;
    -ms-scroll-snap-type: x mandatory;
    scroll-snap-type: x mandatory;
    padding: 5rem 0
}