.sectionHero_c{
    position: relative;
    padding: 8rem;
    background:linear-gradient(0deg, rgba(0, 0, 0,.5), rgba(0, 0,0, 0.5)), url("../../assets/images/headerBg_1.jpg");
    background-size: cover;
    background-position: center;
}
.sectionHeroTitle{
    text-align: center;
}
.sectionHeroLinks{
    align-items: center;
    font-weight: 500;
    padding: .5rem 2rem;
    background: #333;
    position: absolute;
    bottom: 0;
    color: #fff;
    gap: .5rem;
}
.sectionHeroLinks a{
    height: 100%;
    transition: color .2s;
}
.sectionHeroLinks a:hover{
    color: var(--secondary-gold);
}
.sectionHeroTitle{
    text-transform: capitalize;
}