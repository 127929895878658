.reviewsItem_c{
    width: 31%;
    background: #333;
    padding: 2rem;
    transition: all .3s;
}
.reviewsItem_c:hover{
    box-shadow: 1rem 1rem var(--secondary-gold);
}
.reviewsItem{
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}
.reviewText{
    color: #aaa;
    font-weight: 500;
}
.reivewProfileImg{
    width: 3rem;
    aspect-ratio: 1/1;
    object-fit: cover;
}
.reivewProfile{
    gap: 1rem;    
}
.reivewProfileAbout{
    display: flex;
    flex-direction: column;
}
.reivewProfileJob{
    color: #aaa;
    font-size: .8rem;
}
.reviewsStars{
    display: flex;
    gap: 2px;
    align-items:center;
}
.reviewsStars *{
    color: #ffd900;
}