.navbar {
    justify-content: space-between;
    color: #f2f2f2;
    padding: 1rem 15rem;
    position: fixed;
    background: transparent;
    z-index: 29;
    width: 100%;
}
.navbar-brand {
    width: 8.5%;
    min-width: 8.5%;
}
.nav-list {
    gap: 2rem;
    margin: 0;
}
.nav-item {
    height: 100%;
}
.navbar-brand img {
    width: 100%;
}
.nav-colored {
    background: #202020 !important;
}
.dropdown-menu{
    padding: 1rem;
    background: #333333fa !important;
    border-radius: .5rem !important;
}
.dropdown-toggle{
    background: transparent !important;
    padding: 0 !important;
    border-radius: 0;
}
.navSelected{
    color: var(--secondary-gold) !important;
}





