@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap');

/* GLOBAL  */
* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    
    color: #fff;
}
p {
    margin: 0;
}
body {
    background: #292929;
}
.lang{
    font-family: "Poppins", sans-serif !important;
}
.ar{
    font-family: "Cairo", sans-serif !important;
}
html,
body {
    font-size: 16px;
}
.btn {
    padding: .8rem 1.5rem !important;
    outline: none;
    border: none;
}
.d-btn {
    font-size: 1rem;
    border: 1px solid #cda274;
    font-weight: 400;
    color: #cda274;
}
.d-btn:hover {
    background: #cda274 !important;
    color: #f2f2f2 !important;
    transition: all .2s;
}
.btn.focus, .btn:focus{
    box-shadow: none;
}
img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
:root {
    --primary-grey: #292929;
    --secondary-gold: #cda274;
}
.flex-v {
    display: flex;
    align-items: center;
}
a {
    color: #fff;
    text-decoration: none;
}
a:hover{
    color: #fff;
    text-decoration: none;
}
ul {
    list-style-type: none;
}
.container {
    max-width: 100% !important;
    padding: 2rem 9rem;
}
.underline_link {
    border-bottom: 2px solid transparent !important;
    transition: all .2s !important;
    width: fit-content !important;
}
.underline_link:hover {
    border-bottom: 2px solid #cda274 !important;
    color: #fff;
}
.underline_link:focus{
    box-shadow: none !important;
}
.images_c {
    flex-wrap: wrap;
}
.flex-v {
    display: flex;
    flex-direction: column;
}
.flex-h {
    display: flex;
    align-items: center;
}
.onload_c{
    position: fixed;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #33333366;
    z-index: 99;
}
.onload{
    border: 8px solid #EAF0F6;
    border-radius: 50%;
    border-top: 8px solid var(--secondary-gold);
    width: 80px;
    height: 80px;
    animation: spinner 4s linear infinite;
}
@keyframes spinner {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
.loaded{
    display: none;
}
.aspect-v{
    aspect-ratio: 1/1.2 !important;
    -webkit-aspect-ratio: 1/1.2 !important;
}
.aspect-h{
    aspect-ratio: 1.2/1 !important;
    -webkit-aspect-ratio: 1.2/1 !important;
}
.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6{
    line-height: 1.8;
}

/* MOBILE NAV */
.navOpen {
    font-size: 3rem;
    position: fixed;
    top: 2rem;
    left: 2rem;
    z-index: 90;
    background: #333;
    line-height: 0;
    padding: 1rem;
}
.MNav {
    width: 60%;
    left: -61%;
    transition: all .3s;
    overflow: hidden;
}
.isOpen {
    left: 0%;
}
.isClose {
    left: -61%;
}
.nav-transparent {
    background: transparent !important;
}

/* DROPDOWN */
.dropdown-toggle {
    cursor: pointer;
}
.dropdown-menu {
    background-color: transparent;
    border: none;
    border-radius: 0;
}
.dropdown-item {
    color: #fff;
    padding-left: 0;
    padding-right: 0;
    border-bottom: 2px solid transparent;
    width: fit-content;
}
.dropdown-item:hover {
    background-color: transparent;
    color: #fff;
    border-bottom: 2px solid #cda274;
}

/* POPUP IMAGE */
.popup-media {
    position: fixed;
    max-height: fit-content;
    left: 50%;
    top: 55%;
    transform: translate(-50%,-50%);
    width: auto;
    z-index: 30;
}
.popup-media img {
    border: .8rem solid var(--secondary-gold);
    max-height: 77vh;
    max-width: 80vw;
    width: auto;
    object-fit: cover;
    line-height: 0;
}
.popup-close {
    cursor: pointer;
    position: fixed;
    color: #fff;
    background: #333;
    font-size: 1.2rem;
    padding: 0rem .5rem;
    top: -.8rem;
    border-radius: 1rem;
    right: -.8rem;
    z-index: 24;
    transition: all .2s;
}
.popupBg{
    position: absolute;
    width: 500vw;
    height: 500vh;
    background-color: #32323243;
    z-index: -1;
    right: -141%;
    top: -100rem;
}



/* ---------------------------------------------------------- */
@media all and (max-width: 1200px){
    .navbar{
        padding: 1.5rem;
        justify-content: center;
    }
}
@media only screen and (max-width: 992px) {  
    .navbar {
        display: none;
    } 
    .MCI_overlay {
        height: 100%;
    }
    .container{
        padding-left: 3rem ;
        padding-right: 3rem ;
    }
    .navbar{
        padding: 1rem 15rem;
    }
    .navbar-brand {
        width: 8% !important;
    } 
    .cataloguesItem_c{
        width: 33%;
    }
}
@media all and (max-width: 768px) {
    body,html{
        font-size: 10px;
    }
    .container {
        padding-left: 3rem;
        padding-right: 3rem;
    }
    .hero{
        flex-direction: column;
        gap: 3rem;
        background-position: left;
    }
    .heroContent{
        order: 1;
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 2rem;
    }
    .heroVideo_c{
        order: 0;
        width: 80%;
    }
    .hero *{
        text-align:start;
        align-items: center;
    }
    .heroContact_c{
        flex-direction: column;
        width: 100%;
    }
    .heroContact_c .readBtn{
        width: 40%;
        text-align: center;
        border-radius: .8rem;
    }
    .heroContact_c .social_c{
        justify-content: center;
    }
    .heroContact{
        width: 55%;
    }
    .heroLinks_c{
        display: flex;
        gap: 1.2rem;
    }
    .phoneN{
        font-size: 1.2rem;
        width: 100%;
        text-align: center;
    }
    .social_c svg{
        width: 1.7rem;
        height: auto;
    }
    .heroTexts{
        width: 80%;
    }
    .mainCategories_c {
        flex-direction: column;
        align-items: center;
    }
    .aboutUs-c {
        flex-direction: column-reverse;
    }
    .reviews_c {
        align-items: center;
    }
    .aboutUsTexts,
    .aboutUsImgs {
        width: 100% !important;
    }
    .aboutUsTexts,
    .footerLeft {
        align-items: center !important;
    }
    .services_c {
        flex-direction: column;
    }
    .servicesAside {
        width: 100% !important;
    }
    .imagesBox {
        width: 50% !important;
    }
    .services_c .images_c {
        width: 100% !important;
    }
    .aboutUsImgs {
        max-height: none !important;
    }
    .aboutUsImg_1 {
        align-self: auto !important;
    }
    .footer_c {
        padding: 2rem 3rem !important;
    }
    .footer_c *{
        font-size: 1.5rem;
    }
    .footerSection {
        flex-direction: column;
        align-items: flex-start;
    }
    .footerSection>* {
        text-align: start !important;
        justify-content: start !important;
        width: 100% !important;
    }
    .footerLinks {
        flex-direction: row;
    }    
    .heroTitle {
        white-space: wrap !important;
    }
    .MCI_overlay {
        opacity: 1;
        height: 100%;
    }
    .MCI_c {
        width: 100%;
    }
    .MC_overlayText {
        font-size: 3rem;
    }
    .MC_overlaySub {
        font-size: 1.5rem;
    }
    .contact_c {
        flex-direction: column;
        gap: 2rem;
    }
    .AsideBox_c {
        width: 100% !important;
    }
    .AsideTextsTitle{
        font-size: 1.2rem;
    }
    .AsideTextsText{
        font-size: 1rem;
    }
    .gMap {
        width: 100% !important;
    }
    .MCI_overlay {
        height: 100%;
    }
    .afterLabel{
        font-size: 1.2rem;
    }
    .beforeLabel {
        height: 98.7% !important;
        transform: translate(-50%, -49.3%) !important;
    }
    .aboutUsTexts{
        text-align: center !important;
    }
    .heroTexts>*{
        margin-bottom: 1rem;
    }
    .sectionHero_c{
        padding: 8rem 2rem;
    }
    .sectionHeroLinks a,.sectionHeroLinks svg{
        font-size: 1.5rem;
    }
    .popup-close{
        font-size: 2.5rem;
        padding: 0 1rem;
        top: -1rem;
        border-radius: 50%;
    }
    .cataloguesItem_c{
        width: 50%;
    }
    .catalogueContent{
        padding: .2rem;
    }
    .catalogueTitle{
        font-size: 1rem;
    }
    .cataloguesItem_c{
        width: 50%;
    }
}
@media only screen and (min-width: 992px) {
    .navOpen,.MNav {
        display: none;
    }
}
@media only screen and (min-width: 1200px) {
    .beforeAfterContent img {
        -o-object-fit: none;
        object-fit: none;
    }
}

