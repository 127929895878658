.catalogues-c{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}
.cataloguesItem_c{    
    padding: .8rem .5rem;
    width: 25%;
    overflow: hidden;
    align-self: stretch;
}
.cataloguesItem{
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    overflow: hidden;
    text-align: center;
}
.catalogueContent{
    padding: .5rem;
    width: 100%;
}
.catalogueImg_c{
    border-radius: .5rem;
    position: relative;
    transition: all .2s;
    aspect-ratio: 1.5 / 1;
    overflow: hidden;
}
.catalogueImg{
    transition: all .3s;
}
.catalogueTitle{
    font-size: 1.2rem;
    border-bottom: 2px solid transparent;
}
.catalogueDesc{
    width: 100%;
}

.cataloguesItem:hover img{
    transform: scale(1.02);
}
.cataloguesItem:hover .catalogueTitle{
    color: var(--secondary-gold);
}