.footer * {
    text-align: center;
}

.footer_c {
    display: flex;
    flex-direction: column;
    background-image: url("../../assets/images/footerBg.png");
    background-size: cover;
    background-repeat: no-repeat;
    padding: 2rem 5rem;
    gap: 1rem;
    overflow: hidden;
}

.footerShowBtn {
    padding: 0;
    color: #fff;
    width: 70%;
}

.footerSection>* {
    display: flex;
    text-align: center;
    width: calc(100% / 3);
}
.footerShowBtn_c,.footerPhone_c,.footerLocation{
    justify-content: end;
}
hr {
    border: 2px solid var(--secondary-gold);
    opacity: 1 !important;
}

.footerCopyRight,
.footerSocial,
.footerMail {
    justify-content: center;
}

.footerUp_c {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 2rem;
}

.footerDown_c {
    justify-content: space-between;
}

.footerSection {
    gap: 2rem;
}

.footerMail {
    text-align: center;
}

.footerLogo {
    font-weight: 600;
    color: var(--secondary-gold);
}

.footerLeft {
    align-items: flex-start;
}

.footerLinks {
    gap: 1.5rem;
}

.footerSocial {
    display: flex;
    align-items: center;
    gap: .5rem;
}

.footerSocial * {
    background: #333;
    color: #ddd;
    border-radius: 50%;
    padding: 3px;
    line-height: 1;
}
.footerSocial a:hover,.footerSocial a:hover * {
    background: var(--secondary-gold);
    color: #ddd;
}
.footerPhone {
    gap: .5rem;
    align-items: center;
}

.footerLinks a {
    height: 100%;
    border-bottom: 2px solid transparent;
    transition: all .2s;
}

.footerLinks a:hover {
    border-bottom: 2px solid var(--secondary-gold);
}