.contact_c{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.contactAsideBox_c{
    width: 35%;
}
.gMap{
    width: 60%;
    height: 50vh;
}