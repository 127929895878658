.MCI_c{
    position: relative;
    width: calc(100% / 3);
    transition: all 2s;
    overflow: hidden;
}
.mainCategoryItem{
    overflow: hidden;
}
.MCI_overlay{
    display: flex;
    opacity: 0;
    flex-direction: column;
    justify-content: flex-end;
    padding: 3rem;
    position: absolute;
    width: 100%;
    height: 100%;
    cursor: pointer;
    transition: all .2s;
    background: linear-gradient(transparent,#333333);    
}
.MCI_c:hover .MCI_overlay{
    opacity: 1;
}
.MC_overlaySub{
    font-weight: 500;
}
.MC_overlayText{
    font-size: 1.5rem;
    font-weight: 500;
    color: #fff;
    border-width: 3px !important;
    border-bottom: 3px solid var(--secondary-gold) !important;
    transition: all .2s !important;
    width: fit-content !important;
}
.MC_overlayText:hover{
    color: var(--secondary-gold);
    border-bottom: 3px solid #fff !important;
}