.notFound {
    position: absolute;
    left: 50%;
    top: 50%;
    text-align: center;
    transform: translate(-50%, -50%);
    font-size: 5rem;
}

.notFound,
.notFound * {
    color: #ccc;
}

.AsideBox_c {
    display: flex;
    flex-direction: column;
    gap: .8rem;
    padding: 2rem;
    background: #333;
    width: 100%;
}


.AsideBoxItems,
.linksBoxLink {
    width: 100%;
}

.AsideBoxItems {
    display: flex;
    flex-direction: column;
    gap: .5rem;
}

.AsideBoxTitle {
    font-size: 1.3rem;
}

.AsideBoxItem {
    background: var(--primary-grey);
    padding: 1rem;
    transition: all .2s;
}

.AsideBoxItem:hover {
    background: var(--secondary-gold);
}

.AsideTextsBoxItem:hover {
    background: var(--primary-grey) !important;
}

.AsideTextsBoxItem {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.AsideTextsItem_c {
    display: flex;
    flex-direction: column;
    gap: .5rem;
}

.AsideTextsBoxItem path {
    min-width: 2rem;
    color: var(--secondary-gold);
}

.AsideTextsBoxItem svg {
    min-width: 10%;
}

.AsideTextsTitle {
    font-weight: 500;
}

.AsideTextsText {
    font-size: .8rem;
    color: #aaa;
}

.servicesAside {
    display: flex;
    flex-direction: column;
    width: 30%;
    gap: 2rem;
}

.services_c {
    display: flex;
    gap: 1rem;
}
.services_c .imagesBox img{
    aspect-ratio: 1/1.1;
}
.images_c {
    position: relative;
}

.services_c .images_c {
    width: 70%;
    display: flex;
}



