button{
    border: none;
    outline: none;
    background: transparent;
}
.hero{
    height: 80vh;
    width: 100%;
    background:linear-gradient(0deg, rgba(0, 0, 0,.8), rgba(0, 0,0, 0.8)), url("../../assets/images/showroomBg.jpg");
    background-size:cover;
    background-repeat:no-repeat ;
    background-position:center;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    color: #fff;
}
.heroTexts{
    padding: 0 2rem;
    border-left: 4px solid #cda274;
    color: #fff;
}
.heroTextSub{
    color: #cda274;
    font-size: 1rem;
    font-weight: 500;
}
.heroText_1{
    font-size: 3rem;
    font-weight: 200;
}
.heroTitle{
    font-size: 3rem;
    font-weight: 500;
    line-height: 1;
    -webkit-white-space: nowrap;
}
.heroText_3{
    font-size: 1.3rem;
    font-weight: 500 !important;
}
.heroContent{
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}
.heroContact_c{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.heroContact{
    display: flex;
    align-items: center;
    gap: .5rem;
}
.heroLinks_c{
    display: flex;
    gap: 1rem;
}
.readBtn{
    min-width:fit-content ;
    background: #ddd !important;
    border-radius: .7rem;
}
.social_c{
    display: flex;
    align-items: center;
    gap: .5rem;
}
.social_c .heroInsta path{
    padding: 3px;
    color: #ED3E39;
}
.social_c .heroFacebook path{
    padding: 3px;
    color: #0866FF;
}
.social_c svg{
    width: 1.5rem;
    height: auto;
}
.readBtn:hover{
    background: var(--secondary-gold);
}
.readBtn:hover path, .readBtn:hover .heroBtnText{
    color: #ddd;
}
.heroBtnText{
    color: #333;
    font-weight: 500;
}
.heroContent,.heroVideo_c{
    width: 50%;
}
.heroVideo_c{
    overflow: hidden;
    position: relative;
    border: .4rem solid var(--secondary-gold);
    border-radius: .5rem;
}
.heroVideo_c video{
    display: block;
}
.heroVideo_c video:hover + .videoPlayBtn{
    display: block;
}
.videoPlayBg{
    position: absolute;
    width: 110%;
    height: 100%;
    opacity: .5;
    background: #000;
    z-index: 1;
}
.videoPlayBtn{
    position: absolute;
    font-size: 4rem;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    border-radius: 50%;
    background: var(--secondary-gold);
    border-radius: 50%;
    cursor: pointer;
    z-index: 20;
    display: flex;
    transition: all .3s;
}
.videoPlayBtn:hover path{
    color: var(--secondary-gold);
}
.videoPlayBtn:hover{
    background: #f2f2f2;
}
.videoPlayBtn svg{
    padding: 1rem;
}
.videoPlayBtn path{
    color: #f2f2f2;
    margin-left: .2rem;
}
.phoneN{
    font-size: 1rem;
    padding: .7rem;
    border-radius: .5rem;
    border: 1px solid var(--secondary-gold);
    transition: all .2s;
}
.phoneN:hover{
    background: var(--secondary-gold);
}